.main.menu {
    background: rgba(255, 255, 255, 0.05);
    padding: 15px 40px;
    border-radius: 0 0 10px 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    .logo {
        width: 15%;

        img {
            max-height: 128px;
            filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
        }
    }

    .mobile-menu-toggle {
        display: none;
        z-index: 1;

        button {
            border: none;
            background: #c2c2c2c2;
            color: white;
            border-radius: 5px;
            transform: scale(1.5);

            &:hover {
                background: #c2c2c2;
            }
        }
    }

    .items {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            position: relative;
            padding: 10px 20px;
            margin: 0 8px;
            color: #fff;
            font-size: 1rem;
            font-weight: 500;
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 5px;
            transition: all 0.3s ease;
            overflow: hidden;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
                border-color: rgba(255, 255, 255, 0.5);
            }

            .selector {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #fff;
                transition: width 0.3s ease, left 0.3s ease;

                &.selected {
                    background: lime;
                }
            }

            &.active .selector,
            &:hover .selector {
                width: 100%;
                left: 0;
            }
        }

        .tag {
            position: absolute;
            display: flex;
            top: -8px;
            right: -8px;
            width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #fff;
            background-color: #ff5252;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            font-size: 0.75rem;
            font-weight: bold;
            z-index: 2;
        }
    }
}

.main.menu.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 90;

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 64px;
            height: 64px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .main.menu {
        .mobile-menu-toggle {
            display: block;
        }

        .items {
            position: absolute;
            width: 100%;
            height: 100vh;
            opacity: 1;
            top: 0;
            left: 0;
            background-color: #eee;
            overflow-y: hidden;
            transition: opacity .2s ease, scale .5s ease;
            z-index: 2;

            button:not(.close):not(.icon) {
                background: linear-gradient(to right, black, transparent),
                            linear-gradient(to left, black, transparent),
                            linear-gradient(to bottom, white, white);
                border: none;
                height: 64px;
            }

            &.hidden {
                scale: 0;
                opacity: 0;
            }

            .close {
                position: absolute;
                top: 1em;
                right: 1em;
                padding: 10px 15px;
                background-color: red;
            }
        }
    }
}