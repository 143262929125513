.shop-rent {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    gap: 16px;

    .card {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        flex: 1 0 24%;
        row-gap: 16px;
        max-width: 285px;
        min-width: 285px;
        min-height: 400px;
        padding: 10px;
        border-radius: 6px;
        overflow: hidden;
        background-color: #f9f9f9;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        z-index: 0;

        &:hover {
            box-shadow: 0 10px 25px rgba(255 255 255 / .4);
        }

        &.active {
            i {
                opacity: 0;
            }

            .purchase-btn {
                background: #1e7e34 !important;
                padding-left: 16px !important;
            }
        }

        .hidden {
            display: none !important;
        }

        .tags {
            position: absolute;
            top: 15px;
            left: 0;
            min-width: 60px;
            z-index: 1;

            .tag {
                min-width: 125px;
                min-height: 15px;
                color: white;
                text-align: center;
                font-size: 14px;
                font-weight: bold;
            }

            .price {
                background: #ff4d4f;
            }

            .brand {
                background: dodgerblue;
                text-transform: uppercase;
            }
        }

        .header {
            position: static;
            width: 100%;
            border-radius: inherit;
            height: 200px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.4s ease;

                &:hover {
                    scale: 1.1;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            .title {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 1rem !important;
                color: #333;
                text-align: center;
                margin-bottom: 10px;
            }

            .description {
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                color: #555;
                line-height: 1.5;
                text-align: center;
                margin-bottom: 20px;
            }
        }

        .actions {
            display: flex;
            justify-content: center;
            flex-flow: column wrap;

            .price {
                color: red;
                font-weight: 600;
                text-transform: capitalize;
            }

            .purchase-btn {
                position: relative;
                width: 100%;
                height: 36px;
                border: none;
                border-radius: 5px;
                padding-left: 24px;
                color: #fff;
                background: dodgerblue;
                cursor: pointer;
                font-size: 1rem;
                font-weight: 600;
                text-transform: uppercase;
                transition: .3s;
                transition-timing-function: ease-in;

                i {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 37px;
                    align-content: center;
                    border-bottom-right-radius: 25px;
                    background: red;
                    height: 100%;

                    svg {
                        font-size: 16px
                    }
                }
            }
        }
    }
}

.flex-container {

    .card {
        border-right: 1px solid rgba(0 0 0 / .3);
        border-radius: 6px;
        background: #f9f9f9;
        padding: 16px;
    }

    h2 {
        color: black !important;
        font-weight: bold;
    }

    input,
    label {
        font-size: 18px;

        :focus {
            outline: none;
        }
    }

    input:checked {
        border: none;
    }

    input[type="text"] {
        width: 100%;
        padding-left: 16px;
        border: 1px solid #c2c2c2;
        border-radius: 5px;
    }
}