html {
    scroll-behavior: smooth;
}

* {
    transition: all ease-in-out .5s;
    font-family: 'Poppins', ui-sans-serif;
}

h1, h2 {
    position: relative;
    color: white !important;
}

h1 {
    font-size: 40px !important;
}

h2 {
    font-size: 24px !important;
}

body {
    background-color: #eee !important;
    background-size: cover;
    background-repeat: no-repeat;

    a {
        text-decoration: none;
        color: white;
    }

    div {
        white-space: pre-line
    }

    .loading,
    .maintenance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        row-gap: 3rem;

        svg {
            scale: 3;
            color: dodgerblue;
        }
    }

    .loading svg {
        animation: rotate 1s linear infinite;
    }

    .maintenance svg {
        animation: hammerWorking 2s linear infinite;
    }

    section:has(.menu.main) {
        z-index: 1;
    }

    section {
        position: relative;
        min-height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0;

        &.show {
            opacity: 1;
        }

        .row {
            .menu.main {
                text-align: center;
            }

            .title {
                position: relative;
                font-size: 32px !important;
                font-weight: 600 !important;
            }
        }

        .flex-container {
            position: relative;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            padding: 0 16px;
        }
    }

    .icon {
        position: relative;
        display: flex;
        width: 46px;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 100% !important;
        background: dodgerblue !important;
        overflow: visible !important;
        transition: background 0.3s ease, border-color 0.3s ease;

        svg {
            color: #fff;
            font-size: 22px;
            z-index: 1;
        }

        &:hover {
            background: #70a6ff !important;
            border-color: #70a6ff !important;
        }
    }

    section:nth-child(even) .title {
        color: black !important;
    }

    section:nth-child(odd)::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .card-white {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        color: #444;
        line-height: 1.8;
    }

    footer {
        min-height: 450px;
        background: #1c2331;
        color: white !important;
        overflow: hidden;

        .touch {
            background: #6351ce;

            p {
                font-size: 18px
            }

            .icon {
                background: none !important;
                border: none !important;

                &:hover {
                    background: #70a6ff !important;
                }
            }
        }

        .title,
        .contact {
            display: flex;
            flex-direction: column;
            row-gap: 6px;

            h3 {
                margin: 0 0 -10px;
                text-transform: uppercase;
            }

            hr {
                width: 80px;
                height: 2px;
                background-color: #7c4dff;
            }

            svg {
                margin-right: 16px
            }
        }

        .map > div {
            height: 300px !important;
        }

        .copyrights {
            height: 50px;
            margin-top: auto;
            align-content: center;
            text-align: center;
            background: rgba(0, 0, 0, 0.2);
        }
    }
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.ReactModal__Overlay--after-open {
    opacity: 1 !important;
    transform: scale(1) !important;
}

.ReactModal__Overlay {
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 9999;

    .ReactModal__Content {
        position: relative;
        padding: 0 !important;
        inset: 10px !important;
        border-radius: 15px !important;
        background-color: #f7f7f7 !important;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        overflow: hidden;

        .close {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 40px;
            height: 40px;
            padding: 0;
            border: none;
            border-radius: 50%;
            background-color: #ff5a5a;
            color: white;
            cursor: pointer;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #ff4040;
            }

            svg {
                font-size: 24px;
            }
        }

        .modal-content {
            display: grid;
            grid-template-rows: 35% auto;
            height: 100%;

            .modal-header {
                background: no-repeat center / cover;
                box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
                border-radius: 15px 15px 0 0;
                padding: 20px;
                text-align: center;
            }

            .modal-body {
                display: grid;
                grid-template-rows: 100px auto;
                justify-content: center;
                align-items: center;
                padding: 0 20px 20px;

                .card-white {
                    max-width: 100%;
                }

                .title {
                    color: black;
                    margin: 0 auto;
                    font-weight: bold;
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                }

                .description {
                    text-align: left;

                    #loader {
                        font-size: 6rem;
                        color: dodgerblue;
                        animation: rotate 1s linear infinite;
                    }
                }
            }
        }
    }
}

:focus {
    outline: none;
}

.clickable {
    cursor: pointer;
}

@keyframes hammerWorking {
    0% {
        transform: rotate(-30deg);
    }
    50% {
        transform: rotate(50deg);
    }
    100% {
        transform: rotate(-30deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(45deg);
    }
    50% {
        transform: rotate(90deg);
    }
    75% {
        transform: rotate(135deg);
    }
    100% {
        transform: rotate(180deg);
    }
}