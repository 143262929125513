.fabrications {

    .card {
        max-width: 350px;
        height: clamp(450px, 550px, 600px);
        border: none;
        border-radius: 16px;
        padding: 0;
        margin: 0 auto;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        text-align: center;
        overflow: hidden;
        transition: transform 0.4s ease, box-shadow 0.4s ease;
    }

    .card:hover {
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
        transform: translateY(-10px) scale(1.02);
    }

    .card-photo img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        transition: opacity 0.3s ease;
    }

    .card-photo:hover img {
        opacity: 0.85;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
    }

    .card-title {
        font-size: 1.5rem;
        font-weight: 600;
        color: #222;
        margin-bottom: 10px;
    }

    .card-text {
        font-size: 1rem;
        color: #555;
        margin-bottom: 20px;
        line-height: 1.6;
    }

    .card-button {
        width: 150px;
        background: linear-gradient(135deg, dodgerblue, #000000bd);
        color: #fff;
        border: none;
        padding: 12px 30px;
        font-size: 1rem;
        border-radius: 30px;
        cursor: pointer;
        margin: 0 auto;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            background-color: #1e90ff;
            transform: translateY(-2px);
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
        }
    }
}

.contact-form {
    input,
    textarea {
        display: block;
        margin: 0 auto;
        width: 100%;
        padding: 5px 5px 5px 15px;
        border: 1px solid black;
        border-radius: 5px;
        outline: none;
        transition: .3s;
    }

    button {
        position: relative;
        width: 100%;
        padding: 5px;
        border: none;
        border-radius: 5px;
        background: #1e6eff;
        color: white;

        &:hover {
            background: dodgerblue;
        }
    }
}