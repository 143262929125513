.services {

    .card {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 50% 40% 10%;
        max-width: 450px;
        height: 500px;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);

        .image {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .image img {
            width: 160px;
            height: 160px;
            border: 2px solid black;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
        }

        .text {
            overflow: hidden;

            h3 {
                font-weight: bold;
                text-align: center;
            }

            .description {
                padding: 1rem;
                text-align: center;
            }
        }

        button {
            border: none;
            background: none;
            padding: 0.5rem;
            color: rgb(13, 128, 255);
            font-weight: 600;

            span {
                margin-left: 10px;
                font-weight: normal;
            }

            &.hidden {
                display: none;
            }
        }

        &.clickable {
            cursor: pointer;
        }
    }

    .card:hover {
        background: rgba(255, 255, 255, 1);
        transition: background 0.5s ease;
    }
}