.about-us-section {
    padding: 60px 20px;
    background: #f5f5f5;

    .container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .images {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
        position: relative;
    }

    .image-wrapper {
        position: relative;
        width: 320px;
        height: 320px;
    }

    .image-main {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 6px solid #ffffff;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 1s ease, transform 1s ease;
        opacity: 1;
    }

    .image-main.hidden {
        opacity: 0;
    }

    .image-main.rotate {
        transform: rotate(360deg);
    }

    .image-overlay {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 3px solid #ffffff;
        background-color: rgba(255, 255, 255, 0.6);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .header .title {
        font-size: 2.5rem;
        color: #333;
        margin-bottom: 40px;
    }

    .description {
        text-align: left;
    }
}